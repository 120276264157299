import * as TraceKit from 'tracekit';

import { API } from '../API/API';
import { InfoCollector } from '../Collector';
import QueryString from '../../helpers/QueryString';

import { __EGGFISHA__ } from '../../types/types';
import { SessionUUIDManager } from '../DataManagers/SessionUUIDManager';
import { DeviceUUIDManager } from '../DataManagers/DeviceUUIDManager';
import { SDKFetchError } from '../../helpers/SDKFetchError';

export class Sender {
  private static info = {
    adblock: InfoCollector.collect('adblock')
      .then(adblock => (adblock !== null ? adblock : false))
      .catch(() => false),
    languages: InfoCollector.collect('languages').catch(() => null),
    page_stats: InfoCollector.collect('page_stats').catch(() => null),
    permissions: InfoCollector.collect('permissions')
      .then(permissions => permissions ?? ({ geolocation: 'Unknown', notifications: 'Unknown' } as const))
      .catch(() => ({ geolocation: 'Unknown', notifications: 'Unknown' } as const)),
    platform: InfoCollector.collect('platform').catch(() => null),
    screen_resolution: InfoCollector.collect('screen_resolution')
      .then(result => result ?? { width: 0, height: 0 })
      .catch(() => ({ width: 0, height: 0 })),
    timezone_offset: InfoCollector.collect('timezone_offset').catch(() => null),
    timezone: InfoCollector.collect('timezone').catch(() => null),
  };

  private isPageviewDataAlreadySended = false;

  constructor(
    private readonly _API: API,
    private readonly _SessionUUIDManager: SessionUUIDManager,
    private readonly _DeviceUUIDManager: DeviceUUIDManager
  ) {}

  get userID() {
    return { DeviceUUID: this._DeviceUUIDManager.get(), SessionUUID: this._SessionUUIDManager.get() };
  }

  event = (payload: __EGGFISHA__.Sender.EventPayload) => this._API.event(payload, this.userID);

  pageview = async ({ pvID, time, url, referer, props }: __EGGFISHA__.Sender.PageviewPayload) => {
    const response = await this._API.pageview(
      {
        pvID,
        time,
        url,
        referer,
        props,

        adblock: await Sender.info.adblock,
        languages: await Sender.info.languages,
        permissions: await Sender.info.permissions,
        platform: await Sender.info.platform,
        screen_resolution: await Sender.info.screen_resolution,
        stats: this.isPageviewDataAlreadySended ? null : await Sender.info.page_stats,
        timezone_offset: await Sender.info.timezone_offset,
        timezone: await Sender.info.timezone,
      },
      this.userID
    );

    if (response.ok && !this.isPageviewDataAlreadySended) {
      this.isPageviewDataAlreadySended = true;
    }

    return response;
  };

  notBounce = () => this._API.notBounce(this.userID);

  operation = (payload: __EGGFISHA__.Sender.OperationPayload) => this._API.operation(payload, this.userID);

  error = ({ source, tags, error, misc }: __EGGFISHA__.Sender.ErrorPayload) => {
    const stackTrace = TraceKit.computeStackTrace(error, 25);

    const errorJSON: {} = error instanceof SDKFetchError ? error.toJSON() : JSON.parse(JSON.stringify(error));

    return this._API.error(
      {
        source,
        tags,
        error: {
          type: stackTrace.name || 'Error',
          message: stackTrace.message || error.message || 'UNDEFINED',
          stack_trace: stackTrace.stack.map(frame => ({
            name: (frame.func || '<anonymous>').replace('?', '<anonymous>'),
            parameters: frame.args.map(param => ({ name: param })),
            file_name: frame.url,
            line_number: frame.line || 0,
            column: frame.column || 0,
          })),
          modules: [],
        },
        request: {
          user_agent: navigator.userAgent,
          is_secure: location.protocol === 'https:',
          host: location.hostname,
          port: location.port ? parseInt(location.port, 10) : 80,
          path: location.pathname,
          referrer: document.referrer || null,
          query_string: QueryString.getAll(),
        },
        misc: { ...misc, errorJSON },
      },
      this.userID
    );
  };

  log = (payload: __EGGFISHA__.Sender.LogPayload) => this._API.log(payload, this.userID);

  timings = async (payload: __EGGFISHA__.Sender.TimingsPayload) => this._API.timings(payload, this.userID);

  noop = () => this._API.noop(this.userID);
}
