import LocalStorage from '../../helpers/LocalStorage';

import { __EGGFISHA__ } from '../../types/types';

export class NotBouncedSessionUUIDManager {
  constructor(private readonly key: string) {}

  get = () => LocalStorage.get(this.key);

  set = (SessionUUID: __EGGFISHA__.Domain.SessionUUID) => LocalStorage.set(this.key, SessionUUID);
}
