import { Config } from './config';
import { EggFisha } from './EggFisha';

(window => {
  window[Config.WindowConstructorPropertyName] = EggFisha;

  window[Config.WindowCallbacksPropertyName] = window[Config.WindowCallbacksPropertyName] || [];

  const somethingInCallbacksProperty = window[Config.WindowCallbacksPropertyName];

  if (Array.isArray(somethingInCallbacksProperty)) {
    while (somethingInCallbacksProperty.length) {
      const something = somethingInCallbacksProperty.shift();

      if (typeof something === 'function') {
        try {
          something();
        } catch (e) {}
      }
    }

    somethingInCallbacksProperty.push = (...callbacks: unknown[]) => {
      while (callbacks.length) {
        const something = callbacks.shift();

        if (typeof something === 'function') {
          try {
            something();
          } catch (e) {}
        }
      }

      return 0;
    };
  }
})(window);
