import { AbstractCollector } from './Collectors/AbstractCollector';

type FilterByCollectorName<
  TCollector extends AbstractCollector<string, unknown>,
  TName extends TCollector['name']
> = Extract<TCollector, AbstractCollector<TName, unknown>>;

type InferCollectorData<TCollector extends AbstractCollector<string, unknown>> = TCollector extends AbstractCollector<
  string,
  infer TData
>
  ? TData
  : never;

export type CollectionResult<TName, TData> = {
  name: TName;
  data: TData | null;
  error: Error | null;
};

export class InfoCollectorService<TCollectors extends AbstractCollector<string, unknown> = never> {
  private readonly collectors: TCollectors[] = [];

  addCollector = <TNewCollector extends AbstractCollector<string, unknown>>(
    module: TNewCollector
  ): InfoCollectorService<TCollectors | TNewCollector> => {
    this.collectors.push(module as TCollectors & TNewCollector);

    return this as InfoCollectorService<TCollectors | TNewCollector>;
  };

  collect = async <TName extends TCollectors['name']>(name: TName) => {
    const collect = this.collectors.find(collector => collector.name === name)?.collect;

    if (collect) {
      return collect() as InferCollectorData<FilterByCollectorName<TCollectors, TName>>;
    }

    throw new Error(`No collector with such name: ${name}`);
  };
}
