import { __EGGFISHA__ } from '../types/types';

const isMapLikeObject = (object: unknown): object is { [s: string]: unknown } =>
  Object.prototype.toString.call(object) === '[object Object]';

const isNonNull = <TValue>(value: TValue | null): value is TValue => value !== null;

const isNonNullPair = <TValue>(
  pair: [key: string, value: TValue | null] | readonly [key: string, value: TValue | null]
): pair is [key: string, value: TValue] => isNonNull(pair[1]);

const _stringifyValuesDeep = <TTarget>(target: TTarget): __HELPERS__.StringOnlyJson | null => {
  if (target === undefined || target === null) {
    return null;
  }

  switch (typeof target) {
    case 'object':
      if (isMapLikeObject(target)) {
        return Object.fromEntries(
          Object.entries(target)
            .map(([key, value]) => [key, _stringifyValuesDeep(value)] as const)
            .filter(isNonNullPair)
        );
      } else if (Array.isArray(target)) {
        return target.map(_stringifyValuesDeep).filter(isNonNull);
      }
    case 'boolean':
      return target ? '1' : '0';
    case 'number':
      return String(target);
    case 'string':
      return target;
    case 'bigint':
    case 'function':
    case 'symbol':
    default:
      return null;
  }
};

export const stringifyValuesDeep = (target: Record<string, unknown>) =>
  Object.fromEntries<__HELPERS__.StringOnlyJson>(
    Object.entries(target)
      .map(([key, value]) => [key, _stringifyValuesDeep(value)] as const)
      .filter(isNonNullPair)
  );
