type CookieOptions = {
  path: string;
  domain: string;

  expires: string | Date;
  'max-age': number;
  secure: boolean;
  samesite: 'strict' | 'lax';
};

class Cookies {
  set = (name: string, value: string, options: Partial<CookieOptions> = { path: '/' }) => {
    const options_ = { ...options };

    if (options_.expires && options_.expires instanceof Date) {
      options_.expires = options_.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (let optionKey in options_) {
      updatedCookie += '; ' + optionKey;

      let optionValue = options_[optionKey as keyof CookieOptions];

      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    document.cookie = updatedCookie;
  };

  get = (name: string) => {
    let matches = document.cookie.match(
      new RegExp('(?:^|; )' + encodeURIComponent(name).replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );

    return matches ? decodeURIComponent(matches[1]) : null;
  };
}

export default new Cookies();
