import { InfoCollectorService } from './InfoCollector';
import { PropertyCollector } from './Collectors/PropertyCollector';
import {
  AdBlockCollector,
  PageStatsCollector,
  PermissionsCollector,
  ScreenResolutionCollector,
  TimezoneCollector,
  TimingsCollector,
} from './Collectors/ComplexCollectors';

export const InfoCollector = new InfoCollectorService()
  .addCollector(new AdBlockCollector('adblock'))
  .addCollector(new PageStatsCollector('page_stats'))
  .addCollector(new PermissionsCollector('permissions'))
  .addCollector(new PropertyCollector('languages', async () => (navigator.languages ? [...navigator.languages] : [])))
  .addCollector(new PropertyCollector('platform', async () => navigator.platform ?? null))
  .addCollector(new PropertyCollector('timezone_offset', async () => new Date().getTimezoneOffset()))
  .addCollector(new ScreenResolutionCollector('screen_resolution'))
  .addCollector(new TimezoneCollector('timezone'))
  .addCollector(new TimingsCollector('timings'));
