import { tryParseOrNull, ErrorResponse } from './fetch';

export class SDKFetchError extends Error {
  public readonly name = 'SDKFetchError';

  static readonly fromRequest = (request: XMLHttpRequest) => {
    const parsedData = tryParseOrNull<ErrorResponse>(request.responseText);

    return new SDKFetchError(request.statusText)
      .setStatus(request.status)
      .setData(parsedData !== null ? parsedData : request.responseText || request.response);
  };

  public data: ErrorResponse | null = null;
  public event: string | null = null;
  public payload: object | null = null;
  public reason: 'status' | 'abort' | 'error' | 'timeout' | 'forgotten' | 'unknown' = 'unknown';
  public status: number = 0;
  public url: string | null = null;

  constructor(message: string | null = null) {
    super(String(message));
  }

  setData = (data: SDKFetchError['data']) => ((this.data = data), this);
  setEvent = (event: SDKFetchError['event']) => ((this.event = event), this);
  setPayload = (payload: SDKFetchError['payload']) => ((this.payload = payload), this);
  setReason = (reason: SDKFetchError['reason']) => ((this.reason = reason), this);
  setStatus = (status: SDKFetchError['status']) => ((this.status = status), this);
  setUrl = (url: SDKFetchError['url']) => ((this.url = url), this);

  toJSON = () => ({
    url: this.url,
    status: this.status,
    reason: this.reason,
    message: this.message,
    event: this.event,
    payload: this.payload,
    data: this.data,
  });
}
