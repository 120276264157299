export class QueryString {
  private static readonly parsePart = (pair: string): [key: string, value: string] | null => {
    const splited = pair.split('=');

    if (splited.length === 1) {
      return [splited[0], ''];
    } else if (splited.length >= 2) {
      return [splited[0], splited.slice(1).join('=')];
    }

    return null;
  };

  get = (key: string, getSearch = () => location.search) => {
    const search = getSearch();

    if (search === '') {
      return null;
    }

    const parameters = search.slice(1).split('&');

    for (let i = 0; i < parameters.length; i++) {
      const pair = QueryString.parsePart(parameters[i]);

      if (pair && pair[0] === key) {
        return pair[1];
      }
    }

    return null;
  };

  getAll = (getSearch = () => location.search) => {
    const search = getSearch();

    if (search === '') {
      return {};
    }

    const result: Partial<Record<string, string | string[]>> = {};

    const parameters = search.slice(1).split('&');

    for (let i = 0; i < parameters.length; i++) {
      const pair = QueryString.parsePart(parameters[i]);

      if (pair) {
        const value = result[pair[0]];

        if (Array.isArray(value)) {
          value.push(pair[1]);
        } else if (typeof value === 'string') {
          result[pair[0]] = [value, pair[1]];
        } else {
          result[pair[0]] = pair[1];
        }
      }
    }

    return result;
  };
}

export default new QueryString();
