const INT_32_MIN_VALUE = -2_147_483_648;
const INT_32_MAX_VALUE = 2_147_483_647;

type Int32 = number;

export const toInt32 = (number: number): [isInt32: boolean, fittedInt32: Int32] => {
  if (Number.isNaN(number)) {
    return [false, 0];
  }

  if (Number.isInteger(number)) {
    if (number >= INT_32_MIN_VALUE && number <= INT_32_MAX_VALUE) {
      return [true, number];
    } else if (number < INT_32_MIN_VALUE) {
      return [false, INT_32_MIN_VALUE];
    } else if (number > INT_32_MAX_VALUE) {
      return [false, INT_32_MAX_VALUE];
    }
  }

  return [false, 0];
};
