import Time from './helpers/Time';

import { __EGGFISHA__ } from './types/types';

export const SDKVersion: __EGGFISHA__.Domain.SDKVersion = 1;

export const Config = {
  KeyPrefix: '_egg',

  WindowCallbacksPropertyName: `eggfisha_callbacks_${SDKVersion}` as const,
  WindowConstructorPropertyName: '_EggFisha' as const,

  DeviceUUIDStorageKey: 'DeviceUUID',

  SessionUUIDStorageKey: 'SessionUUID',
  SessionUUIDStorageKeyMaxAge: Time.MINUTE * 30,
  SessionUUIDUpdateInterval: Time.SECOND,

  SessionMarkersStorageKey: 'SessionMarkers',

  NotBouncedSessionUUIDStorageKey: 'notBouncedSessionUUID',
  NotBounceTimeout: Time.SECOND * 15,

  DeviceUUIDQueryName: 'egguid',

  UtmSourceQueryName: 'utm_source',
  UtmMediumQueryName: 'utm_medium',
  UtmTermQueryName: 'utm_term',
  UtmContentQueryName: 'utm_content',
  UtmIdQueryName: 'utm_id',
  UtmCampaignQueryName: 'utm_campaign',

  YClientIDQueryName: 'yclid',
  GClientIDQueryName: 'gclid',
  FBClientIDQueryName: 'fbclid',

  ApiKeyQueryName: 'api_key',

  EventEndpoint: 'https://egg.afisha.ru/v1/event/common',
  PageviewEndpoint: 'https://egg.afisha.ru/v1/event/paginavisum',
  NotBounceEndpoint: 'https://egg.afisha.ru/v1/event/not-bounce',
  TimingEndpoint: 'https://egg.afisha.ru/v1/event/pagetimings',
  OperationEndpoint: 'https://egg.afisha.ru/v1/event/operation',
  ErrorEndpoint: 'https://egg.afisha.ru/v1/event/error',
  NoopEndpoint: 'https://egg.afisha.ru/v1/event/noop',

  RequestTimeout: Time.SECOND * 10,

  SDKDebugModeStorageKey: '__EGGFISHA_DEBUG__',
};
