import { Config } from '../config';

import { __EGGFISHA__ } from '../types/types';
import { SDKFetchError } from './SDKFetchError';

type SuccessResponse = {
  deviceUuid: __EGGFISHA__.Domain.DeviceUUID;
};

export type ErrorResponse = Swagger.Schemas.ProblemDetails | string;

type OkResult = {
  ok: true;
  successData: SuccessResponse | null;
};

type NotOkResult = {
  ok: false;
  error: SDKFetchError | null;
};

type Result = OkResult | NotOkResult;

export const tryParseOrNull = <TData>(data: string) => {
  try {
    return JSON.parse(data) as TData;
  } catch (e) {
    return null;
  }
};

export const fetch = (url: string, body: object, headers: Record<string, string | boolean | number> = {}) =>
  new Promise<Result>(resolve => {
    const request = new XMLHttpRequest();

    request.open('POST', url, true);

    request.setRequestHeader('Content-Type', 'text/plain');

    Object.entries(headers).forEach(([key, value]) => {
      request.setRequestHeader(key, String(value));
    });

    request.withCredentials = true;
    request.timeout = Config.RequestTimeout;

    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status >= 200 && request.status < 300) {
          resolve({
            ok: true,
            successData: tryParseOrNull<SuccessResponse>(request.responseText),
          });
        } else {
          resolve({
            ok: false,
            error: SDKFetchError.fromRequest(request).setUrl(url).setPayload(body).setReason('status'),
          });
        }
      }
    };

    request.onabort = () =>
      resolve({
        ok: false,
        error: SDKFetchError.fromRequest(request).setUrl(url).setPayload(body).setReason('abort'),
      });

    request.ontimeout = () =>
      resolve({
        ok: false,
        error: SDKFetchError.fromRequest(request).setUrl(url).setPayload(body).setReason('timeout'),
      });

    request.onerror = () =>
      resolve({
        ok: false,
        error: SDKFetchError.fromRequest(request).setUrl(url).setPayload(body).setReason('error'),
      });

    request.send(JSON.stringify(body));

    setTimeout(() => {
      resolve({
        ok: false,
        error: SDKFetchError.fromRequest(request).setUrl(url).setPayload(body).setReason('forgotten'),
      });
    }, Config.RequestTimeout);
  });
