export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });

export const getSecondLevelDomain = (getHostname = () => location.hostname) => {
  const levels = getHostname().split('.');

  return '.' + levels.slice(levels.length - 2, levels.length).join('.');
};

export const onDOMContentLoaded = (callback: () => void) => {
  if (document.readyState === 'loading') {
    const handler = () => {
      callback();

      document.removeEventListener('DOMContentLoaded', handler);
    };

    document.addEventListener('DOMContentLoaded', handler);
  } else {
    callback();
  }
};

export const onLoad = (callback: () => void) => {
  if (document.readyState === 'loading' || document.readyState === 'interactive') {
    const handler = () => {
      if (document.readyState === 'complete') {
        callback();

        window.removeEventListener('load', handler);
      }
    };

    window.addEventListener('load', handler);
  } else {
    callback();
  }
};

export const log = (prefix: string, info: string, details = {}) => {
  console.groupCollapsed(`[EggFisha] [${new Date().toLocaleTimeString('ru')}] ${prefix} (${info})`);

  Object.entries(details).forEach(([key, value]) => {
    console.log(`%c${key.padEnd(20)}`, 'font-weight: 900;', value);
  });

  console.groupEnd();
};
