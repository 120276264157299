import { ComplexCollector } from '../ComplexCollector';

export class AdBlockCollector<TName extends string> extends ComplexCollector<TName> {
  // https://github.com/wmcmurray/just-detect-adblock/blob/master/src/helpers.js#L45
  private static readonly createBait = () => {
    const bait = document.createElement('div');

    bait.setAttribute(
      'class',
      'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links ad-text adSense adBlock adContent adBanner'
    );
    bait.setAttribute(
      'style',
      'width: 1px !important; height: 1px !important; position: absolute !important; left: -10000px !important; top: -1000px !important;'
    );

    return bait;
  };

  // https://github.com/wmcmurray/just-detect-adblock/blob/master/src/helpers.js#L58
  private static readonly isbaitBlocked = (element: HTMLElement) => {
    if (
      element.offsetParent === null ||
      element.offsetHeight == 0 ||
      element.offsetLeft == 0 ||
      element.offsetTop == 0 ||
      element.offsetWidth == 0 ||
      element.clientHeight == 0 ||
      element.clientWidth == 0
    ) {
      return true;
    } else if (window.getComputedStyle !== undefined) {
      const elemCS = window.getComputedStyle(element, null);

      if (elemCS && (elemCS.display === 'none' || elemCS.visibility == 'hidden')) {
        return true;
      }
    }

    return false;
  };

  private static readonly isDOMBlocker = () => {
    const bait = AdBlockCollector.createBait();

    document.body.appendChild(bait);

    const isAffectedByAdBlock = AdBlockCollector.isbaitBlocked(bait);

    bait.remove();

    return isAffectedByAdBlock;
  };

  collect = async () => AdBlockCollector.isDOMBlocker();
}
