import { ComplexCollector } from '../ComplexCollector';

const get = async (name: PermissionName) => {
  try {
    return ({
      denied: 'Denied',
      granted: 'Granted',
      prompt: 'Prompt',
    } as const)[(await navigator.permissions.query({ name })).state];
  } catch (e) {
    return 'Unknown';
  }
};

export class PermissionsCollector<TName extends string> extends ComplexCollector<TName> {
  collect = async () => {
    if (typeof navigator.permissions !== 'undefined') {
      return {
        geolocation: await get('geolocation'),
        notifications: await get('notifications'),
      } as const;
    }

    return null;
  };
}
