import { Config } from '../../config';
import { fetch } from '../../helpers/fetch';
import { stringifyValuesDeep } from '../../helpers/stringifyValuesDeep';

import { __EGGFISHA__ } from '../../types/types';

export class API {
  constructor(private readonly apiKey: __EGGFISHA__.Domain.ApiKey) {}

  private _fetch = (url: string, body: object) => fetch(`${url}?${Config.ApiKeyQueryName}=${this.apiKey}`, body);

  event = async (
    { time, category, action, label, value, props, json }: __EGGFISHA__.API.EventPayload,
    userID: __EGGFISHA__.API.UserID
  ) => {
    const body: Swagger.Schemas.CommonEventCommand = {
      deviceUuid: userID.DeviceUUID,
      sessionUuid: userID.SessionUUID,
      time: time?.toISOString(),
      category,
      action,
      label,
      value,
      extraProps: props ? (stringifyValuesDeep(props) as Record<string, string>) : undefined,
      extraJson: json ? stringifyValuesDeep(json) : undefined,
    };

    return this._fetch(Config.EventEndpoint, body);
  };

  pageview = async (
    {
      pvID,
      time,
      url,
      referer,
      adblock,
      platform,
      languages,
      permissions,
      screen_resolution,
      timezone_offset,
      timezone,
      stats,
      props,
    }: __EGGFISHA__.API.PageviewPayload,
    userID: __EGGFISHA__.API.UserID
  ) => {
    const body: Swagger.Schemas.PageViewEventCommand = {
      deviceUuid: userID.DeviceUUID,
      sessionUuid: userID.SessionUUID,

      pageViewId: pvID,
      time: time?.toISOString(),
      url,
      referer,
      adblock,
      platform,
      languages,
      permissions,
      screen_resolution,
      timezone_offset,
      timezone,
      stats: stats ?? undefined,

      extraProps: props ? (stringifyValuesDeep(props) as Record<string, string>) : undefined,
    };

    return this._fetch(Config.PageviewEndpoint, body);
  };

  notBounce = async (userID: __EGGFISHA__.API.UserID) => {
    const body: Swagger.Schemas.NotBounceSessionCommand = {
      deviceUuid: userID.DeviceUUID,
      sessionUuid: userID.SessionUUID,
    };

    return this._fetch(Config.NotBounceEndpoint, body);
  };

  operation = async (
    { name, body: operationBody }: __EGGFISHA__.API.OperationPayload,
    userID: __EGGFISHA__.API.UserID
  ) => {
    const stringified = stringifyValuesDeep(operationBody);

    const body: __HELPERS__.Simplify<Swagger.Schemas.OperationEventCommand> = {
      deviceUuid: userID.DeviceUUID,
      sessionUuid: userID.SessionUUID,
      name,
      body: stringified,
    };

    return this._fetch(Config.OperationEndpoint, body);
  };

  error = async (
    { source, tags, request, error, misc }: __EGGFISHA__.API.ErrorPayload,
    userID: __EGGFISHA__.API.UserID
  ) => {
    const body: Swagger.Schemas.ErrorEventCommand = {
      deviceUuid: userID.DeviceUUID,
      source,
      tags: ['web-sdk-error', ...tags],
      type: 'Error',
      data: { '@request': request, '@error': error, misc },
    };

    return this._fetch(Config.ErrorEndpoint, body);
  };

  log = async ({ source, tags, misc }: __EGGFISHA__.API.LogPayload, userID: __EGGFISHA__.API.UserID) => {
    const body: Swagger.Schemas.ErrorEventCommand = {
      deviceUuid: userID.DeviceUUID,
      source,
      tags: ['web-sdk-log', ...tags],
      type: 'Log',
      data: { misc },
    };

    return this._fetch(Config.ErrorEndpoint, body);
  };

  timings = async (
    { pvID, timings: { start, middle, end } }: __EGGFISHA__.API.TimingsPayload,
    userID: __EGGFISHA__.API.UserID
  ) => {
    const body: Swagger.Schemas.PageTimingsEventCommand = {
      deviceUuid: userID.DeviceUUID,
      sessionUuid: userID.SessionUUID,
      pageViewId: pvID,
      timingsStart: start ? start : undefined,
      timingsMiddle: middle ? middle : undefined,
      timingsEnd: end ? end : undefined,
    };

    return this._fetch(Config.TimingEndpoint, body);
  };

  noop = async (userID: __EGGFISHA__.API.UserID) => {
    const body: Swagger.Schemas.NoopCommand = {
      deviceUuid: userID.DeviceUUID,
      sessionUuid: userID.SessionUUID,
    };

    return this._fetch(Config.NoopEndpoint, body);
  };
}
