import { ComplexCollector } from '../ComplexCollector';

export class TimezoneCollector<TName extends string> extends ComplexCollector<TName> {
  collect = async () => {
    if (window.Intl && window.Intl.DateTimeFormat) {
      return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    return null;
  };
}
