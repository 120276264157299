import { getSecondLevelDomain } from '../../helpers/utils';
import Cookies from '../../helpers/Cookies';
import LocalStorage from '../../helpers/LocalStorage';

import { __EGGFISHA__ } from '../../types/types';

export class DeviceUUIDManager {
  private _DeviceUUID: __EGGFISHA__.Domain.DeviceUUID | null = null;

  constructor(private readonly key: string) {}

  private _set = (DeviceUUID: __EGGFISHA__.Domain.DeviceUUID) => {
    this._DeviceUUID = DeviceUUID;

    try {
      Cookies.set(this.key, DeviceUUID, {
        domain: getSecondLevelDomain(),
        expires: 'Tue, 19 Jan 2038 03:14:07 GMT',
      });

      LocalStorage.set(this.key, DeviceUUID);
    } catch (e) {}
  };

  get = () => this._DeviceUUID ?? Cookies.get(this.key) ?? LocalStorage.get(this.key) ?? null;

  set = (DeviceUUID: __EGGFISHA__.Domain.DeviceUUID) => {
    this._set(DeviceUUID);
  };
}
