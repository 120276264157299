import { API } from './core/API/API';
import { Config } from './config';
import { DeviceUUIDManager } from './core/DataManagers/DeviceUUIDManager';
import { fetch } from './helpers/fetch';
import { log, uuidv4 } from './helpers/utils';
import { NotBouncedSessionUUIDManager } from './core/DataManagers/NotBouncedSessionUUIDManager';
import { SDKFetchError } from './helpers/SDKFetchError';
import { Sender } from './core/Sender/Sender';
import { SessionMarkersManager } from './core/DataManagers/SessionMarkersManager';
import { SessionUUIDManager } from './core/DataManagers/SessionUUIDManager';
import { sha256 } from './helpers/sha256';
import { TimingsManager } from './core/TimingsManager/TimingsManager';
import LocalStorage from './helpers/LocalStorage';
import QueryString from './helpers/QueryString';

import { __EGGFISHA__ } from './types/types';

type EventsArgs = [eventType: typeof Events['EVENT'], payload: __EGGFISHA__.SDK.EventPayload];
type PageviewArgs = [eventType: typeof Events['PAGEVIEW'], payload: __EGGFISHA__.SDK.PageviewPayload];
type OperationArgs = [eventType: typeof Events['OPERATION'], payload: __EGGFISHA__.SDK.OperationPayload];
type LogArgs = [eventType: typeof Events['LOG'], payload: __EGGFISHA__.SDK.LogPayload];
type ErrorArgs = [eventType: typeof Events['ERROR'], payload: __EGGFISHA__.SDK.ErrorPayload];
type NotBounceArgs = [eventType: typeof Events['NOT_BOUNCE']];

type CombinedArgs = EventsArgs | PageviewArgs | OperationArgs | LogArgs | ErrorArgs | NotBounceArgs;

const Events = {
  EVENT: 'event',
  PAGEVIEW: 'pageview',
  OPERATION: 'operation',
  NOT_BOUNCE: 'not_bounce',
  LOG: 'log',
  ERROR: 'error',
} as const;

export class EggFisha {
  public static readonly utils = { sha256 };

  private readonly _Sender: Sender;
  private readonly _SessionUUIDManager: SessionUUIDManager;
  private readonly _NotBouncedSessionUUIDManager: NotBouncedSessionUUIDManager;
  private readonly _SessionMarkersManager: SessionMarkersManager;
  private readonly _DeviceUUIDManager: DeviceUUIDManager;
  private readonly _TimingsManager: TimingsManager;

  private isDebug: boolean = false;

  public readonly isReady = true;

  public readonly utils = EggFisha.utils;

  constructor(apiKey: __EGGFISHA__.Domain.ApiKey, counterID: __EGGFISHA__.Domain.CounterID) {
    if (!apiKey) {
      throw new Error('apiKey is required (1 argument)');
    }

    if (!counterID) {
      throw new Error('counterID is required (2 argument)');
    }

    const deviceUUIDKey = `${Config.KeyPrefix}${Config.DeviceUUIDStorageKey}`;
    const sesionUUIDKey = `${Config.KeyPrefix}${counterID}_${Config.SessionUUIDStorageKey}`;
    const notBouncedSessionUUIDKey = `${Config.KeyPrefix}${counterID}_${Config.NotBouncedSessionUUIDStorageKey}`;
    const sessionMarkersKey = `${Config.KeyPrefix}${counterID}_${Config.SessionMarkersStorageKey}`;

    this._DeviceUUIDManager = new DeviceUUIDManager(deviceUUIDKey);
    this._SessionUUIDManager = new SessionUUIDManager(sesionUUIDKey);
    this._NotBouncedSessionUUIDManager = new NotBouncedSessionUUIDManager(notBouncedSessionUUIDKey);
    this._SessionMarkersManager = new SessionMarkersManager(sessionMarkersKey);

    this._Sender = new Sender(new API(apiKey), this._SessionUUIDManager, this._DeviceUUIDManager);

    this._TimingsManager = new TimingsManager(this._Sender);

    if (this._SessionMarkersManager.isSessionUUIDShouldBeRegenerated()) {
      this._SessionUUIDManager.regenerate();
    }

    if (this._SessionMarkersManager.isAnyDifferenceBetweenCurrentAndPreviuos()) {
      this._SessionMarkersManager.saveCurrent();
    }

    const DeviceUUIDFromQueryString = QueryString.get(Config.DeviceUUIDQueryName);

    if (DeviceUUIDFromQueryString) {
      this.setDeviceUUID(DeviceUUIDFromQueryString);
    }

    const isDebug = LocalStorage.get(Config.SDKDebugModeStorageKey) !== null;

    if (isDebug) {
      this.enableDebugMode();
    }

    setInterval(() => {
      this._SessionUUIDManager.update();
    }, Config.SessionUUIDUpdateInterval);

    if (this.NotBouncedSessionUUID !== this.SessionUUID) {
      setTimeout(() => {
        this.send(Events.NOT_BOUNCE).then(() => {
          this._NotBouncedSessionUUIDManager.set(this.SessionUUID);
        });
      }, Config.NotBounceTimeout);
    }
  }

  public get DeviceUUID() {
    return this._DeviceUUIDManager.get();
  }

  public get SessionUUID() {
    return this._SessionUUIDManager.get();
  }

  public get SessionMarkers() {
    return this._SessionMarkersManager.getCurrent();
  }

  public get NotBouncedSessionUUID() {
    return this._NotBouncedSessionUUIDManager.get();
  }

  public enableDebugMode = () => {
    this.isDebug = true;

    return this;
  };

  public setDeviceUUID = (DeviceUUID: __EGGFISHA__.Domain.DeviceUUID) => {
    this._DeviceUUIDManager.set(DeviceUUID);

    return this;
  };

  public getDeviceUUID = async () => {
    const DeviceUUID = this._DeviceUUIDManager.get();

    if (DeviceUUID) {
      return DeviceUUID;
    }

    const response = await this._Sender.noop();

    if (response.ok && response.successData?.deviceUuid) {
      this._DeviceUUIDManager.set(response.successData.deviceUuid);

      return response.successData.deviceUuid;
    } else {
      return null;
    }
  };

  public async send(eventType: typeof Events['EVENT'], payload: __EGGFISHA__.SDK.EventPayload): Promise<void>;
  public async send(eventType: typeof Events['PAGEVIEW'], payload: __EGGFISHA__.SDK.PageviewPayload): Promise<void>;
  public async send(eventType: typeof Events['OPERATION'], payload: __EGGFISHA__.SDK.OperationPayload): Promise<void>;
  public async send(eventType: typeof Events['LOG'], payload: __EGGFISHA__.SDK.LogPayload): Promise<void>;
  public async send(eventType: typeof Events['ERROR'], payload: __EGGFISHA__.SDK.ErrorPayload): Promise<void>;
  public async send(eventType: typeof Events['NOT_BOUNCE']): Promise<void>;
  public async send(...args: CombinedArgs) {
    let request: ReturnType<typeof fetch> = new Promise(resolve =>
      resolve({
        ok: false,
        error: new SDKFetchError('Wrong eventType').setEvent(args[0]).setPayload(args[1] ?? null),
      })
    );

    switch (args[0]) {
      case Events.EVENT:
        {
          const payload = args[1];

          if (this.isDebug) {
            log(
              args[0],
              `category: "${payload.category}", action: "${payload.action}", label: "${payload.label}"`,
              payload
            );
          }

          request = this._Sender.event(payload);
        }
        break;
      case Events.PAGEVIEW:
        {
          const pvID = uuidv4();

          const payload = {
            time: new Date(),
            url: window.location.href,
            referer: document.referrer,
            ...args[1],
            pvID,
          };

          if (this.isDebug) {
            log(args[0], `url: "${payload.url}", referer: "${payload.referer}"`, payload);
          }

          request = this._Sender.pageview(payload).then(response => {
            if (response.ok && !this._TimingsManager.pvID) {
              this._TimingsManager.setPvID(pvID);
            }

            return response;
          });
        }
        break;
      case Events.OPERATION:
        {
          const payload = args[1];

          if (this.isDebug) {
            log(args[0], `name: "${payload.name}"`, payload);
          }

          request = this._Sender.operation({ body: {}, ...payload });
        }
        break;
      case Events.LOG:
        {
          const payload = {
            source: window.location.href,
            tags: [],
            ...args[1],
          };

          if (this.isDebug) {
            log(args[0], `source: "${payload.source}", tags: "${JSON.stringify(payload.tags)}"`, payload);
          }

          request = this._Sender.log(payload);
        }
        break;
      case Events.ERROR:
        {
          const payload = {
            source: window.location.href,
            tags: [],
            ...args[1],
          };

          if (this.isDebug) {
            log(args[0], `source: "${payload.source}", tags: "${JSON.stringify(payload.tags)}"`, payload);
          }

          request = this._Sender.error(payload);
        }
        break;
      case Events.NOT_BOUNCE:
        request = this._Sender.notBounce();
        break;
    }

    const response = await request;

    if (response.ok) {
      if (response.successData) {
        this._DeviceUUIDManager.set(response.successData.deviceUuid);
      }
    } else if (args[0] !== Events.ERROR && response.error) {
      if (response.error.status || response.error.data) {
        this.send(Events.ERROR, { error: response.error });
      }
    }
  }
}
