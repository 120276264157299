import { ComplexCollector } from '../ComplexCollector';

const range = (start: number, end: number) => end - start;

const nullableRange = (start: number, end: number) => (start && end ? end - start : null);

export class TimingsCollector<TName extends string> extends ComplexCollector<TName> {
  collect = async () => {
    const timing = (window.performance || window.webkitPerformance)?.timing;
    const firstContentfulPaintEntry: PerformanceEntry | null =
      performance.getEntriesByType('paint').filter(({ name }) => name === 'first-contentful-paint')[0] ?? null;

    if (timing) {
      const ns = timing.navigationStart;

      return {
        DNSTiming: range(timing.domainLookupStart, timing.domainLookupEnd),
        ConnectTiming: range(timing.connectStart, timing.connectEnd),
        ResponseStartTiming: range(timing.requestStart, timing.responseStart),
        ResponseEndTiming: range(timing.responseStart, timing.responseEnd),
        FetchTiming: range(ns, timing.fetchStart),
        RedirectTiming: range(timing.redirectStart, timing.redirectEnd),

        DOMInteractiveTiming: nullableRange(timing.domLoading, timing.domInteractive),
        DOMContentLoadedTiming: nullableRange(timing.domContentLoadedEventStart, timing.domContentLoadedEventEnd),
        DOMCompleteTiming: nullableRange(ns, timing.domComplete),
        LoadEventStartTiming: nullableRange(ns, timing.loadEventStart),
        LoadEventEndTiming: nullableRange(timing.loadEventStart, timing.loadEventEnd),
        NSToDOMContentLoadedTiming: nullableRange(ns, timing.domContentLoadedEventStart),

        FirstPaintTiming: firstContentfulPaintEntry ? Math.round(firstContentfulPaintEntry.startTime) : null,
      };
    }

    return null;
  };
}
