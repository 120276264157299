import { ComplexCollector } from '../ComplexCollector';

import { toInt32 } from '../../../../helpers/toInt32';

export class PageStatsCollector<TName extends string> extends ComplexCollector<TName> {
  collect = async () => {
    const featureTimings =
      (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming | undefined) ?? null;

    const navigation = window.performance.navigation;

    // prettier-ignore
    const navigationType =
      navigation.type === navigation.TYPE_BACK_FORWARD
        ? 'BackForward'
        : navigation.type === navigation.TYPE_RELOAD
          ? 'Reload'
          : 'Navigate';

    const [isEncodedBodySizeInt32, encodedBodySizeInt32] = toInt32(featureTimings?.encodedBodySize ?? 0);
    const [isDecodedBodySizeInt32, decodedBodySizeInt32] = toInt32(featureTimings?.decodedBodySize ?? 0);

    return {
      EncodedBodySize: isEncodedBodySizeInt32 ? encodedBodySizeInt32 : 0,
      DecodedBodySize: isDecodedBodySizeInt32 ? decodedBodySizeInt32 : 0,
      HistoryLength: window.history.length,
      Navigation: navigationType,
    } as const;
  };
}
