import { Config } from '../../config';
import QueryString from '../../helpers/QueryString';
import LocalStorage from '../../helpers/LocalStorage';

type SessionMarkers = {
  UtmSource: string | null;
  UtmMedium: string | null;
  UtmTerm: string | null;
  UtmContent: string | null;
  UtmId: string | null;
  UtmCampaign: string | null;

  YClientID: string | null;
  GClientID: string | null;
  FBClientID: string | null;
};

const isSessionMarkersShape = (x: unknown): x is { [Key in keyof SessionMarkers]: unknown } => {
  if (typeof x === 'object' && x !== null) {
    return (
      'UtmSource' in x &&
      'UtmMedium' in x &&
      'UtmTerm' in x &&
      'UtmContent' in x &&
      'UtmId' in x &&
      'UtmCampaign' in x &&
      'YClientID' in x &&
      'GClientID' in x &&
      'FBClientID' in x
    );
  }

  return false;
};

const isSessionMarkers = (x: unknown): x is SessionMarkers => {
  if (isSessionMarkersShape(x)) {
    return (
      (x.UtmSource === null || typeof x.UtmSource === 'string') &&
      (x.UtmMedium === null || typeof x.UtmMedium === 'string') &&
      (x.UtmTerm === null || typeof x.UtmTerm === 'string') &&
      (x.UtmContent === null || typeof x.UtmContent === 'string') &&
      (x.UtmId === null || typeof x.UtmId === 'string') &&
      (x.UtmCampaign === null || typeof x.UtmCampaign === 'string') &&
      (x.YClientID === null || typeof x.YClientID === 'string') &&
      (x.GClientID === null || typeof x.GClientID === 'string') &&
      (x.FBClientID === null || typeof x.FBClientID === 'string')
    );
  }

  return false;
};

export class SessionMarkersManager {
  private static _get = (): SessionMarkers => ({
    UtmSource: QueryString.get(Config.UtmSourceQueryName),
    UtmMedium: QueryString.get(Config.UtmMediumQueryName),
    UtmTerm: QueryString.get(Config.UtmTermQueryName),
    UtmContent: QueryString.get(Config.UtmContentQueryName),
    UtmId: QueryString.get(Config.UtmIdQueryName),
    UtmCampaign: QueryString.get(Config.UtmCampaignQueryName),

    YClientID: QueryString.get(Config.YClientIDQueryName),
    GClientID: QueryString.get(Config.GClientIDQueryName),
    FBClientID: QueryString.get(Config.FBClientIDQueryName),
  });

  constructor(private readonly key: string) {}

  private _get = () => {
    const maybeJsonAsString = LocalStorage.get(this.key);

    try {
      if (maybeJsonAsString) {
        const maybeJson = JSON.parse(maybeJsonAsString);

        if (isSessionMarkers(maybeJson)) {
          return maybeJson;
        }
      }
    } catch (e) {}

    return null;
  };

  private _set = (SessionMarkers: SessionMarkers) => {
    try {
      LocalStorage.set(this.key, JSON.stringify(SessionMarkers));

      return true;
    } catch (e) {
      return false;
    }
  };

  saveCurrent = () => {
    this._set(SessionMarkersManager._get());
  };

  getPrevious = () => this._get();

  getCurrent = () => SessionMarkersManager._get();

  isAnyDifferenceBetweenCurrentAndPreviuos = () => {
    const previousSessionMarkers = this.getPrevious();
    const currentSessionMarkers = this.getCurrent();

    return JSON.stringify(previousSessionMarkers) !== JSON.stringify(currentSessionMarkers);
  };

  isSessionUUIDShouldBeRegenerated = () => {
    const old = this.getPrevious();
    const new_ = this.getCurrent();

    return (
      old && Object.entries(new_).some(([key, value]) => value !== null && old[key as keyof SessionMarkers] !== value)
    );
  };
}
