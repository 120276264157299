export class LocalStorage {
  /**
   * @safe
   */
  get = (key: string) => {
    try {
      return localStorage.getItem(key);
    } catch (e) {}

    return null;
  };

  /**
   * @safe
   */
  remove = (key: string) => {
    try {
      localStorage.removeItem(key);

      return true;
    } catch (e) {
      return false;
    }
  };

  /**
   * @safe
   */
  set = (key: string, value: string) => {
    try {
      localStorage.setItem(key, value);

      return true;
    } catch (e) {
      return false;
    }
  };
}

export default new LocalStorage();
