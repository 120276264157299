import { Config } from '../../config';
import { getSecondLevelDomain, uuidv4 } from '../../helpers/utils';
import Cookies from '../../helpers/Cookies';

import { __EGGFISHA__ } from '../../types/types';

export class SessionUUIDManager {
  private static _generate = () => uuidv4();

  constructor(private readonly key: string) {}

  private _get = () => Cookies.get(this.key);

  private _set = (SessionUUID: __EGGFISHA__.Domain.SessionUUID) => {
    try {
      Cookies.set(this.key, SessionUUID, {
        domain: getSecondLevelDomain(),
        // max-age у кук в секундах
        'max-age': Config.SessionUUIDStorageKeyMaxAge / 1000,
      });

      return true;
    } catch (e) {
      return false;
    }
  };

  regenerate = () => {
    this._set(SessionUUIDManager._generate());
  };

  update = () => {
    const SessionUUID = this._get();

    if (SessionUUID) {
      return this._set(SessionUUID);
    } else {
      return this._set(SessionUUIDManager._generate());
    }
  };

  get = () => {
    const SessionUUID = this._get();

    if (SessionUUID) {
      return SessionUUID;
    } else {
      const newSessionUUID = SessionUUIDManager._generate();

      this._set(newSessionUUID);

      return newSessionUUID;
    }
  };
}
