import { onDOMContentLoaded, onLoad } from '../../helpers/utils';
import { InfoCollector } from '../Collector';
import { Sender } from '../Sender/Sender';

type Timings = {
  start?: __HELPERS__.ConvertDeep<Swagger.Schemas.PageTimingsEventCommand['timingsStart']> | null;
  middle?: __HELPERS__.ConvertDeep<Swagger.Schemas.PageTimingsEventCommand['timingsMiddle']> | null;
  end?: __HELPERS__.ConvertDeep<Swagger.Schemas.PageTimingsEventCommand['timingsEnd']> | null;
};

export class TimingsManager {
  private _pvID: string | null = null;

  private static timings: Timings = {
    start: null,
    middle: null,
    end: null,
  };

  private isSended: Record<keyof Timings, boolean> = {
    start: false,
    middle: false,
    end: false,
  };

  constructor(private readonly _Sender: Sender) {
    onDOMContentLoaded(async () => {
      const timings = await InfoCollector.collect('timings');

      if (timings) {
        TimingsManager.timings.start = {
          ConnectTiming: timings.ConnectTiming,
          DNSTiming: timings.DNSTiming,
          DOMInteractiveTiming: timings.DOMInteractiveTiming,
          FetchTiming: timings.FetchTiming,
          RedirectTiming: timings.RedirectTiming,
          ResponseEndTiming: timings.ResponseEndTiming,
          ResponseStartTiming: timings.ResponseStartTiming,
        };
      }

      this.tryToSend();
    });

    onLoad(() => {
      setTimeout(async () => {
        const timings = await InfoCollector.collect('timings');

        if (timings) {
          TimingsManager.timings.middle = {
            DOMContentLoadedTiming: timings.DOMContentLoadedTiming,
            FirstPaintTiming: timings.FirstPaintTiming,
            NSToDOMContentLoadedTiming: timings.NSToDOMContentLoadedTiming,
          };

          TimingsManager.timings.end = {
            DOMCompleteTiming: timings.DOMCompleteTiming,
            LoadEventEndTiming: timings.LoadEventEndTiming,
            LoadEventStartTiming: timings.LoadEventStartTiming,
          };
        }

        this.tryToSend();
      }, 0);
    });
  }

  private get isThereSomethingToSend() {
    return !!(TimingsManager.timings.start || TimingsManager.timings.middle || TimingsManager.timings.end);
  }

  get isAllTimingsSended() {
    return !!(this.isSended.start && this.isSended.middle && this.isSended.end);
  }

  get pvID() {
    return this._pvID;
  }

  private _send = async (pvID: string) => {
    const response = await this._Sender.timings({
      pvID,
      timings: {
        start: TimingsManager.timings.start && !this.isSended.start ? { ...TimingsManager.timings.start } : null,
        middle: TimingsManager.timings.middle && !this.isSended.middle ? { ...TimingsManager.timings.middle } : null,
        end: TimingsManager.timings.end && !this.isSended.end ? { ...TimingsManager.timings.end } : null,
      },
    });

    if (response.ok) {
      if (TimingsManager.timings.start) {
        this.isSended.start = true;
      }

      if (TimingsManager.timings.middle) {
        this.isSended.middle = true;
      }

      if (TimingsManager.timings.end) {
        this.isSended.end = true;
      }
    }
  };

  private tryToSend = () => {
    if (this.pvID && this.isThereSomethingToSend && !this.isAllTimingsSended) {
      this._send(this.pvID);
    }
  };

  public readonly setPvID = (pvID: string) => {
    if (this._pvID === null) {
      this._pvID = pvID;

      this.tryToSend();
    }
  };
}
